import React, { useContext } from 'react';
import { Booking, ExternalOperation } from '@/services/types';

export interface BookingContextProps {
  booking: Booking;
  operations: ExternalOperation[];
  warningMessages: string[];
}

const defaultContext = {
  booking: {},
  operations: [],
  warningMessages: []
};

export const BookingContext =
  React.createContext<BookingContextProps>(defaultContext);

export const useBookingContext = () => useContext(BookingContext);
