/* eslint-disable @typescript-eslint/no-non-null-assertion */
// markdown-it plugin for:
// 1. adding target="_blank" to external links
// 2. normalize internal links

import MarkdownIt from 'markdown-it';
import { MarkdownEnv } from '../env';
const EXTERNAL_URL_RE = /^[a-z]+:/i;

export const linkPlugin = (
  md: MarkdownIt,
  externalAttrs: Record<string, string>,
  base: string
) => {
  md.renderer.rules.link_open = (
    tokens,
    idx,
    options,
    env: MarkdownEnv,
    self
  ) => {
    const token = tokens[idx];
    const hrefIndex = token.attrIndex('href');
    if (hrefIndex >= 0) {
      const hrefAttr = token.attrs![hrefIndex];
      const url = hrefAttr[1];
      const isExternal = EXTERNAL_URL_RE.test(url);
      if (isExternal) {
        Object.entries(externalAttrs).forEach(([key, val]) => {
          token.attrSet(key, val);
        });
      } else if (
        // internal anchor links
        !url.startsWith('#') &&
        // mail links
        !url.startsWith('mailto:')
      ) {
        normalizeHref(hrefAttr, env);
      }
    }
    return self.renderToken(tokens, idx, options);
  };

  function normalizeHref(hrefAttr: [string, string], env: MarkdownEnv) {
    let url = hrefAttr[1];

    // ensure leading . for relative paths
    if (!url.startsWith('/') && !/^\.\//.test(url)) {
      url = './' + url;
    }

    // export it for existence check
    pushLink(url.replace(/\.html$/, ''), env);

    // append base to internal (non-relative) urls
    if (url.startsWith('/')) {
      url = `${base}${url}`.replace(/\/+/g, '/');
    }

    // markdown-it encodes the uri
    hrefAttr[1] = decodeURI(url);
  }

  function pushLink(link: string, env: MarkdownEnv) {
    const links = env.links || (env.links = []);
    links.push(link);
  }
};
