import React from 'react';
import Portal from '../Portal';
import styles from './mark.module.css';

const Tag: React.FC = () => {
  return (
    <Portal>
      <div className={styles.mark}></div>
    </Portal>
  );
};

export default Tag;
