import { action, computed, makeObservable, observable } from 'mobx';

export class OTP {
  @observable registerOTP = false;
  @observable requestOTP = false;
  @observable verifyOTP = false;

  constructor() {
    makeObservable(this);
  }

  @action
  updateRegisterOTP = (visible: boolean) => {
    this.registerOTP = visible;
  };

  @action
  updateRequestOTP = (request: boolean) => {
    this.requestOTP = request;
  };

  @action
  updateVerifyOTP = (visible: boolean) => {
    this.verifyOTP = visible;
  };

  @computed
  get showOTP() {
    return this.registerOTP || this.requestOTP || this.verifyOTP;
  }
}

export default new OTP();
