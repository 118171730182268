// eslint-disable-next-line
export const noop = () => {};

// eslint-disable-next-line
export const asyncNoop = async () => {};

export const sleep = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const flushPromises = () => sleep(0);

export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const base64ToByteArray = (base64: string) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes: Array<number> = [];
  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

const execCopy = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
};

export const copyToClipboard = (text: string) => {
  if (
    'permissions' in navigator &&
    'clipboard' in navigator &&
    'writeText' in navigator.clipboard
  ) {
    navigator.permissions
      .query({ name: 'clipboard-write' as PermissionName })
      .then(result => {
        if (result.state === 'granted' || result.state === 'prompt') {
          navigator.clipboard.writeText(text);
        } else {
          execCopy(text);
        }
      })
      .catch(() => {
        execCopy(text);
      });
  } else {
    execCopy(text);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isObject = (obj: any): boolean => {
  return obj !== null && typeof obj === 'object';
};

export const isSupportWebAssembly =
  typeof WebAssembly === 'object' &&
  typeof WebAssembly.instantiate === 'function';
