import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import cls from 'clsx';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { isSupportWebAssembly } from '@/utils/misc';
import { IQrReaderProps } from './QrReader.types';
import { ViewFinder } from './ViewFinder';
import styles from './reader.module.css';
import message from '../message';

const defaultConstraints = {
  facingMode: 'environment'
};

const IOS_PERMISSION_DENIED_MSG =
  'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.';
const ANDROID_PERMISSION_DENIED_MSG = 'Permission denied';
const ANDROID_PERMISSION_DISMISSED_MSG = 'Permission dismissed';
const PERMISSION_DENIED_MSGS = [
  IOS_PERMISSION_DENIED_MSG,
  ANDROID_PERMISSION_DENIED_MSG,
  ANDROID_PERMISSION_DISMISSED_MSG
];

const QrReader: React.FC<IQrReaderProps> = ({
  scanDelay = 500,
  constraints = defaultConstraints,
  className = '',
  onResult
}) => {
  const { t } = useTranslation();
  const intervalRef = useRef<number>(0);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const [showReader, setShowReader] = useState(false);

  useEffect(() => {
    if (!isSupportWebAssembly) {
      message.error(t('common.useChrome'));
      Sentry.captureMessage('Your browser does not support WebAssembly.');
    }
  }, [t]);

  useEffect(() => {
    if (videoRef.current) {
      navigator.mediaDevices
        ?.getUserMedia({
          audio: false,
          video: constraints
        })
        .then(stream => {
          if (videoRef.current && !videoRef.current.srcObject) {
            setShowReader(true);
            streamRef.current = stream;
            videoRef.current.srcObject = stream;
            videoRef.current.setAttribute('playsinline', '');
            videoRef.current.play();
          }
        })
        .catch(err => {
          const errorMessage = err?.message;
          if (PERMISSION_DENIED_MSGS.includes(errorMessage)) {
            message.error(t('ticket.enableCamera'), 10);
          } else {
            message.error(errorMessage, 10);
            Sentry.captureMessage(err);
          }
          setShowReader(false);
        });
    }

    return () => {
      // stop the video
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
    };
  }, [scanDelay, constraints, t]);

  const scan = useCallback(async () => {
    const canvas = document.createElement('canvas');
    const video = videoRef.current;
    if (video) {
      const width = video.videoWidth;
      const height = video.videoHeight;
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      if (ctx && width && height && isSupportWebAssembly) {
        ctx.drawImage(video, 0, 0, width, height);
        const imgData = ctx.getImageData(0, 0, width, height);
        const { scanImageData } = await import('@undecaf/zbar-wasm');
        const result = await scanImageData(imgData);
        if (result.length > 0) {
          setShowReader(false);
          onResult?.(result[0]);
          window.clearInterval(intervalRef.current);
          video.pause();
          if (video.srcObject) {
            (video.srcObject as MediaStream)
              .getVideoTracks()
              .forEach(track => track.stop());
            video.srcObject = null;
          }
        }
      }
    }
  }, [onResult]);

  useEffect(() => {
    if (videoRef.current) {
      intervalRef.current = window.setInterval(() => {
        scan();
      }, scanDelay);
    }
    return () => {
      window.clearInterval(intervalRef.current);
    };
  }, [scanDelay, scan]);

  return (
    <section
      className={cls(
        styles.reader,
        {
          [styles.show]: showReader
        },
        className
      )}
    >
      <div className={styles.container}>
        <ViewFinder />
        <video
          muted
          ref={videoRef}
          className={styles.video}
          style={{
            transform:
              constraints?.facingMode === 'user' ? 'scaleX(-1)' : 'none'
          }}
        />
      </div>
    </section>
  );
};

export default memo(QrReader);
