import { action, makeObservable, observable } from 'mobx';

export class DateNow {
  @observable offset = 0;

  constructor() {
    makeObservable(this);
  }

  @action
  updateNow = (dateStr: string) => {
    this.offset = new Date(dateStr).getTime() - Date.now();
  };

  currentTime = () => {
    return Date.now() + this.offset;
  };
}

export default new DateNow();
