import React, { useEffect, useMemo, useRef } from 'react';
import JsBarcode from 'jsbarcode';
import { EntryCodeRawDataCodeType } from '@/services/enum';
import { IBarCodeProps } from './BarCode.types';

const BARCODE_FORMAT: Record<string, string> = {
  [EntryCodeRawDataCodeType.BARCODE_CODE_128]: 'CODE128',
  [EntryCodeRawDataCodeType.BARCODE_CODE_93]: 'CODE93',
  [EntryCodeRawDataCodeType.BARCODE_CODE_39]: 'CODE39'
};

const BarCode: React.FC<IBarCodeProps> = ({ entryCode, style, className }) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const codeType = useMemo(() => {
    return entryCode?.rawData?.codeType || '';
  }, [entryCode?.rawData]);

  const payload = useMemo(() => {
    return entryCode?.rawData?.payload || '';
  }, [entryCode?.rawData]);

  useEffect(() => {
    if (svgRef.current) {
      JsBarcode(svgRef.current, payload, {
        format: BARCODE_FORMAT[codeType]
      });
    }
  }, [codeType, payload]);

  return (
    <svg
      ref={svgRef}
      xmlns="http://www.w3.org/2000/svg"
      stroke="none"
      className={className}
      style={{
        ...style
      }}
    ></svg>
  );
};

export default BarCode;
