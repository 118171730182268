import React, { memo } from 'react';

export interface ILinkyProps {
  as?: keyof JSX.IntrinsicElements;
  text?: string;
  className?: string;
}

function urlify(text: string) {
  const urlRegex = /(https?:\/\/[-a-zA-Z0-9()@:%_+.~#?&/=]+)/g;
  return text.replace(urlRegex, url => {
    return `<a class="linky" href="${url}" rel="noreferrer" target="_blank">${url}</a>`;
  });
}

const Linky: React.FC<ILinkyProps> = ({
  as = 'div',
  text = '',
  className = ''
}) => {
  const Tag = as;
  return (
    <Tag
      className={className}
      dangerouslySetInnerHTML={{ __html: urlify(text) }}
    />
  );
};

export default memo(Linky);
