import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { BaseLayout, BookingContainer, TicketContainer } from '@/layouts';
import NotFound from '@/views/NotFound';
import ErrorBoundary from './ErrorBoundary';

const Booking = lazy(() => import('@/views/Booking'));
const BookingDetails = lazy(() => import('@/views/BookingDetails'));
const TicketHistory = lazy(() => import('@/views/TicketHistory'));
const Advanced = lazy(() => import('@/views/Advanced'));
const TicketDetails = lazy(() => import('@/views/TicketDetails'));

const AppRoutes = () => {
  return (
    <Suspense fallback="Loading...">
      <ErrorBoundary>
        <Routes>
          <Route path="/" element={<BaseLayout />}>
            <Route index element={<NotFound />} />
            <Route
              path="issueticket/:supplierId/:secret/:bookingId"
              element={<BookingContainer />}
            >
              <Route index element={<Booking />} />
              <Route path="details" element={<BookingDetails />} />
            </Route>
            <Route
              path="issueticket/:supplierId/:secret/:bookingId/:ticketId/:distributionId?"
              element={<TicketContainer />}
            >
              <Route index element={<TicketDetails />} />
              <Route path="history" element={<TicketHistory />} />
              <Route path="advanced" element={<Advanced />} />
            </Route>
            <Route path="404" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </ErrorBoundary>
    </Suspense>
  );
};

export default AppRoutes;
