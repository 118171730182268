import React, { useEffect, useRef } from 'react';
import cls from 'clsx';
import { useSwiperContext } from './context';
import styles from './swiper.module.css';
import { ISlideProps } from './Swiper.types';

const Slide: React.FC<ISlideProps> = ({
  className = '',
  index = 0,
  space = 0,
  children
}) => {
  const { setSlidesSizeMapping } = useSwiperContext();
  const slideRef = useRef<HTMLLIElement>(null);
  useEffect(() => {
    const slide = slideRef.current;
    if (!slide) return;

    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
        if (width > 0 && height > 0) {
          setSlidesSizeMapping(prevMapping => ({
            ...prevMapping,
            [index]: { width, height }
          }));
        }
      }
    });

    resizeObserver.observe(slide);
    return () => resizeObserver.disconnect();
  }, [index, setSlidesSizeMapping]);
  return (
    <li
      ref={slideRef}
      className={cls(styles.slide, className)}
      data-index={index}
      style={{
        marginRight: space
      }}
    >
      {children}
    </li>
  );
};

export default Slide;
