import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Loading } from '@/components';
import { eticketPortal } from '@/services';
import { Booking, ExternalOperation } from '@/services/types';
import { updateThemeColor } from '@/utils/color';
import { getCurrentLocale } from '@/utils/locale';
import ud from '@/utils/ud';
import styles from './booking.module.css';
import { BookingContext } from './context';

const BookingContainer: React.FC = () => {
  const { supplierId, secret, bookingId } = useParams();
  const [booking, setBooking] = useState<Booking>({});
  const [operations, setOperations] = useState<ExternalOperation[]>([]);
  const [warningMessages, setWarningMessages] = useState<string[]>([]);
  const loading = useMemo(() => !booking.id, [booking]);
  const { i18n } = useTranslation();
  const getBooking = useCallback(() => {
    eticketPortal
      .GetBooking({
        supplierId,
        secret,
        bookingId,
        ud,
        locale: getCurrentLocale(i18n.language)
      })
      .then(res => {
        if (res?.booking) {
          setBooking(res.booking);
        }
        if (res?.externalOperations) {
          setOperations(res.externalOperations);
        }
        if (res?.warningMessages) {
          setWarningMessages(res.warningMessages);
        }
      });
  }, [supplierId, secret, bookingId, i18n.language]);

  useEffect(() => {
    getBooking();
  }, [getBooking]);

  useEffect(() => {
    if (booking.uiSetting?.colorPrimaryHex) {
      updateThemeColor(booking.uiSetting.colorPrimaryHex);
    }
  }, [booking.uiSetting?.colorPrimaryHex]);

  const context = useMemo(
    () => ({
      booking,
      operations,
      warningMessages
    }),
    [booking, operations, warningMessages]
  );

  return (
    <BookingContext.Provider value={context}>
      {loading ? (
        <div className={styles.loader}>
          <Loading />
        </div>
      ) : (
        <Outlet />
      )}
    </BookingContext.Provider>
  );
};

export default BookingContainer;
