import React from 'react';
import cls from 'clsx';
import styles from './separator.module.css';

export interface ITicketSeparatorProps {
  className?: string;
}

const TicketSeparator: React.FC<ITicketSeparatorProps> = ({
  className = ''
}) => {
  return <div className={cls(styles.separator, className)}></div>;
};

export default TicketSeparator;
