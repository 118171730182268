import sha1 from 'crypto-js/sha1';

const wechatUA = 'MicroMessenger';
const alipayUA = 'AlipayClient';

export const isWechat = new RegExp(wechatUA, 'i').test(navigator.userAgent);
export const isAlipay = new RegExp(alipayUA, 'i').test(navigator.userAgent);

const ua = (() => {
  if (isWechat) {
    return wechatUA;
  } else if (isAlipay) {
    return alipayUA;
  } else {
    return navigator.userAgent;
  }
})();

const ud = sha1(ua).toString();

export default ud;

/**
 * @description Check if the current browser is invalid
 */
export const isInvalidBrowser: boolean = (function () {
  // TODO: Add invalid browsers
  const INVALID_BROWSERS_REGEX = /WpsMoffice|NAVER|SamsungBrowser/i;
  const userAgent = navigator.userAgent;
  return INVALID_BROWSERS_REGEX.test(userAgent);
})();

/**
 * @description check if is invalid supplier
 * @param invalidSuppliers Array of supplier
 */
export const isInvalidSupplier = (invalidSuppliers: string[]) => {
  const supplierRegex = /issueticket\/([^\\/]+)\//;
  const result = location.pathname.match(supplierRegex);
  if (Array.isArray(result) && result.length >= 2) {
    return invalidSuppliers.includes(result[1]);
  } else {
    return false;
  }
};

/**
 * @description Check if the current iOS version is invalid
 */
const INVALID_MIN_IOS_VERSION = '17.4';
const INVALID_MAX_IOS_VERSION = '17.4.1';
const minVersionParts = INVALID_MIN_IOS_VERSION.split('.').map(Number);
const maxVersionParts = INVALID_MAX_IOS_VERSION.split('.').map(Number);
const userAgent = navigator.userAgent;
const regex = /iPhone OS (\d+)_(\d+)_?(\d+)?/;
const inputVersionParts = userAgent.match(regex)?.slice(1).map(Number) || [
  0, 0, 0
];

const compareVersions = (v1: number[], v2: number[]): number => {
  for (let i = 0; i < Math.max(v1.length, v2.length); i++) {
    const num1 = v1[i] || 0;
    const num2 = v2[i] || 0;
    if (num1 > num2) return 1;
    if (num1 < num2) return -1;
  }
  return 0;
};

export const isInvalidIosVersions = (() => {
  const isGreaterThanOrEqualMin =
    compareVersions(inputVersionParts, minVersionParts) >= 0;
  const isLessThanOrEqualMax =
    compareVersions(inputVersionParts, maxVersionParts) <= 0;

  return isGreaterThanOrEqualMin && isLessThanOrEqualMax;
})();
