import MarkdownIt from 'markdown-it';
import { linkPlugin } from './plugins/link';

export interface MarkdownOptions extends MarkdownIt.Options {
  config?: (md: MarkdownIt) => void;
  externalLinks?: Record<string, string>;
}

export type MarkdownRenderer = MarkdownIt;

export const createMarkdownRenderer = (
  options: MarkdownOptions = {},
  base = '/'
): MarkdownRenderer => {
  const md = MarkdownIt({
    html: true,
    linkify: true,
    ...options
  }) as MarkdownRenderer;

  // custom plugins
  md.use(
    linkPlugin,
    { target: '_blank', rel: 'noreferrer', ...options.externalLinks },
    base
  );

  // apply user config
  if (options.config) {
    options.config(md);
  }

  return md;
};
