import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import Routes from '@/routes';

const GTM_ENVIRONMENTS = ['develop', 'production'];

const App: React.FC = () => {
  useEffect(() => {
    if (GTM_ENVIRONMENTS.includes(import.meta.env.VITE_APP_ETICKET_ENV || '')) {
      const tagManagerArgs = {
        gtmId: 'GTM-WF7VLBNJ'
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

export default App;
