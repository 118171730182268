import React, { useContext } from 'react';
import { Ticket } from '@/services/types';
import { asyncNoop, noop } from '@/utils/misc';

export interface TicketContextProps {
  ticket: Ticket;
  setTicket: (ticket: Ticket) => void;
  getTicket: () => Promise<void>;
}

const defaultContext = {
  ticket: {},
  setTicket: noop,
  getTicket: asyncNoop
};

export const TicketContext =
  React.createContext<TicketContextProps>(defaultContext);

export const useTicketContext = () => useContext(TicketContext);
