import React, { forwardRef, useMemo } from 'react';
import cls from 'clsx';
import { NavLink } from 'react-router-dom';
import styles from './button.module.css';
import { IButtonProps } from './Button.types';
import { ReactComponent as Loading } from '@/assets/icons/circle-notch.svg';

function isExternal(href: string) {
  return /^(https?:|mailto:|tel:)/.test(href);
}

const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      children,
      color = 'primary',
      loading = false,
      type = 'button',
      disabled = false,
      className = '',
      href = '',
      target = '_blank',
      to,
      relative,
      ...rest
    }: IButtonProps,
    ref
  ) => {
    const externalProps = useMemo(() => {
      if (isExternal(href)) {
        return {
          target: target || '_blank',
          rel: 'noreferrer'
        };
      }
    }, [href, target]);

    const disableState = useMemo(
      () => disabled || loading,
      [disabled, loading]
    );

    if (href && !disableState) {
      return (
        <a
          href={href}
          className={cls(styles.button, styles[color], className)}
          {...externalProps}
        >
          {children}
        </a>
      );
    } else if (to) {
      return (
        <NavLink
          className={cls(styles.button, styles[color], className)}
          to={to}
          relative={relative}
        >
          <span className={styles.text}> {children} </span>
        </NavLink>
      );
    } else {
      return (
        <button
          ref={ref}
          type={type}
          disabled={disableState}
          className={cls(
            styles.button,
            { [styles[color]]: !disableState },
            className
          )}
          {...rest}
        >
          {loading && <Loading className={styles.icon} />}
          {children}
        </button>
      );
    }
  }
);

Button.displayName = 'Button';

export default Button;
