import React from 'react';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    if (
      error.message.startsWith('Failed to fetch dynamically imported module')
    ) {
      location.reload();
    }
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return null;
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
