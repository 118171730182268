import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, message } from '@/components';
import { copyToClipboard } from '@/utils/misc';
import { ReactComponent as Safari } from '@/assets/icons/safari.svg';
import { ReactComponent as Chrome } from '@/assets/icons/chrome.svg';
import styles from './recommend.module.css';

interface RecommendBrowserProps {
  bookingId?: string;
}

const RecommendBrowser: React.FC<RecommendBrowserProps> = ({ bookingId }) => {
  const { t } = useTranslation();

  const browserList = useMemo(
    () => [
      {
        icon: Safari,
        name: 'Safari'
      },
      {
        icon: Chrome,
        name: 'Chrome'
      }
    ],
    []
  );

  const handleCopy = useCallback(() => {
    copyToClipboard(location.href);
    message.success(t('ticket.copyLinkSuccess'));
  }, [t]);

  return (
    <div className={styles.recommend}>
      {bookingId && (
        <h1 className={styles.recommendTitle}>Booking {bookingId}</h1>
      )}
      <p className={styles.recommendContent}>
        {t('common.recommendBrowserTips')}
      </p>
      <div className={styles.recommendIcons}>
        {browserList.map(({ icon: Icon, name }) => (
          <div className={styles.recommendIcon} key={name}>
            <div className={styles.recommendIconContainer}>
              <Icon className={styles.icon} />
              <span>{name}</span>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.recommendUrl}>
        <div className={styles.recommendUrlContainer}>{location.href}</div>
      </div>
      <Button className={styles.recommendBtn} onClick={handleCopy}>
        {t('common.copyBtn')}
      </Button>
    </div>
  );
};

export default RecommendBrowser;
