import axios from './axios';
import { AxiosRequestConfig, Method, AxiosRequestHeaders } from 'axios';

interface InitReq extends RequestInit {
  pathPrefix?: string;
}

function fetchReq<I, O>(path: string, init?: InitReq): Promise<O> {
  const { pathPrefix, ...req } = init || {};

  const url = pathPrefix ? `${pathPrefix}${path}` : path;

  const reqConfig: AxiosRequestConfig = {
    method: req.method as Method,
    url,
    data: req.body,
    headers: req.headers as AxiosRequestHeaders
  };
  return axios.request<I, O>(reqConfig);
}

export default fetchReq;
