import { action, makeObservable, observable } from 'mobx';

export class TokyoMetro {
  @observable emvLinked = false;
  @observable emvEnabled = true;

  constructor() {
    makeObservable(this);
  }

  @action
  updateEmvLinked = (emvLinked: boolean) => {
    this.emvLinked = emvLinked;
  };

  @action
  updateEmvEnabled = (emvEnabled: boolean) => {
    this.emvEnabled = emvEnabled;
  };
}

export default new TokyoMetro();
