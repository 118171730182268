import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useStore } from '@/stores';
import { eticketPortal } from '@/services';
import { Watermark } from '@/components';
import { isProd } from '@/utils/context';
import ud, { isInvalidBrowser, isInvalidSupplier } from '@/utils/ud';
import Header from '../Header';
import OTP from '../OTP';
import RecommendBrowser from './RecommendBrowser';
import styles from './base.module.css';

const INTERVAL_DURATION = 30_000;

const BaseLayout: React.FC = () => {
  const { dateNow } = useStore();
  const intervalRef = useRef<number>();
  const { supplierId, secret, bookingId, distributionId } = useParams();
  const showRecommendBrowser = useMemo(() => {
    const invalidSuppliers = ['suruttokansai'];
    return isInvalidSupplier(invalidSuppliers) && isInvalidBrowser;
  }, []);

  const fetchNow = useCallback(() => {
    eticketPortal
      .Now({
        bookingId,
        supplierId,
        secret,
        distributionId,
        ud
      })
      .then(res => {
        if (res?.now) {
          dateNow.updateNow(res.now);
        } else {
          window.clearInterval(intervalRef.current);
        }
      });
  }, [bookingId, supplierId, secret, distributionId, dateNow]);

  useEffect(() => {
    if (!showRecommendBrowser) {
      fetchNow();
      intervalRef.current = window.setInterval(() => {
        fetchNow();
      }, INTERVAL_DURATION);
    }

    return () => {
      window.clearInterval(intervalRef.current);
    };
  }, [fetchNow, showRecommendBrowser]);

  return (
    <div className={styles.base}>
      <Header />
      {showRecommendBrowser ? (
        <RecommendBrowser bookingId={bookingId} />
      ) : (
        <>
          <Outlet />
          <OTP />
        </>
      )}
      {!isProd && <Watermark />}
    </div>
  );
};

export default BaseLayout;
