import { useCallback, useState, useRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '@/stores';
import { EMAIL_REGEX } from '@/utils/misc';
import { eticketPortal } from '@/services';
import { getCurrentLocale } from '@/utils/locale';
import ud from '@/utils/ud';
import { Modal, Button, Input, TicketSeparator, message } from '@/components';
import styles from './otp.module.css';

const RESEND_DURATION = 60_000;
const INTERVAL_DURATION = 1_000;

const OTP: React.FC = () => {
  const { t } = useTranslation();
  const { supplierId, secret, bookingId, distributionId } = useParams();
  const intervalRef = useRef<number>();
  const registerRef = useRef(false);
  const [remainingSeconds, setRemainingSeconds] = useState(RESEND_DURATION);
  const [loadingRegisterOTP, setLoadingRegisterOTP] = useState(false);
  const [loadingRequestOTP, setLoadingRequestOTP] = useState(false);
  const [loadingVerifyOTP, setLoadingVerifyOTP] = useState(false);
  const [initRequestOTP, setInitRequestOTP] = useState(false);
  const { otp } = useStore();
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const validResend = useMemo(() => remainingSeconds <= 0, [remainingSeconds]);

  const countdown = useCallback(() => {
    window.clearInterval(intervalRef.current);
    setRemainingSeconds(RESEND_DURATION);
    intervalRef.current = window.setInterval(() => {
      setRemainingSeconds(prevSeconds => {
        if (prevSeconds > 0) {
          return prevSeconds - INTERVAL_DURATION;
        } else {
          return prevSeconds;
        }
      });
    }, INTERVAL_DURATION);
  }, []);

  const registerOTP = useCallback(() => {
    if (email) {
      if (EMAIL_REGEX.test(email)) {
        setLoadingRegisterOTP(true);
        eticketPortal
          .RegisterOTP({
            supplierId,
            bookingId,
            distributionId,
            secret,
            ud,
            locale: getCurrentLocale(),
            email
          })
          .then(res => {
            if (res) {
              registerRef.current = true;
              otp.updateRegisterOTP(false);
              otp.updateVerifyOTP(true);
              countdown();
            }
          })
          .finally(() => {
            setLoadingRegisterOTP(false);
          });
      } else {
        message.warning(t('otp.invalidEmail'));
      }
    } else {
      message.warning(t('otp.inputEmail'));
    }
  }, [email, bookingId, supplierId, distributionId, secret, otp, countdown, t]);

  const requestOTP = useCallback(() => {
    setLoadingRequestOTP(true);
    setInitRequestOTP(false);
    if (registerRef.current) {
      eticketPortal
        .RegisterOTP({
          supplierId,
          bookingId,
          distributionId,
          secret,
          ud,
          locale: getCurrentLocale(),
          email
        })
        .then(res => {
          if (res) {
            message.success(
              t('otp.sendCode', {
                email
              }),
              10
            );
          }
        })
        .finally(() => {
          setLoadingRequestOTP(false);
          countdown();
        });
    } else {
      eticketPortal
        .RequestOTP({
          supplierId,
          bookingId,
          distributionId,
          secret,
          ud,
          locale: getCurrentLocale()
        })
        .then(res => {
          if (res?.maskedEmail) {
            message.success(
              t('otp.sendCode', {
                email: res.maskedEmail
              }),
              10
            );
          }
        })
        .finally(() => {
          setLoadingRequestOTP(false);
          countdown();
        });
    }
  }, [bookingId, supplierId, distributionId, secret, countdown, email, t]);

  const verifyOTP = useCallback(() => {
    if (code) {
      setLoadingVerifyOTP(true);
      eticketPortal
        .VerifyOTP({
          supplierId,
          bookingId,
          distributionId,
          secret,
          code,
          locale: getCurrentLocale()
        })
        .then(res => {
          if (res) {
            otp.updateRequestOTP(false);
            location.reload();
          }
        })
        .finally(() => {
          setLoadingVerifyOTP(false);
        });
    } else {
      message.warning(t('otp.inputPassword'));
    }
  }, [code, bookingId, supplierId, secret, distributionId, otp, t]);

  useEffect(() => {
    if (otp.requestOTP) {
      setInitRequestOTP(true);
    }
  }, [otp.requestOTP]);

  useEffect(() => {
    if (otp.showOTP) {
      document.body.classList.add('showOTP');
    } else {
      document.body.classList.remove('showOTP');
    }
  }, [otp.showOTP]);

  return (
    <div className={styles.otp}>
      <Modal visible={otp.registerOTP}>
        <div className={styles.modal}>
          <div className={styles.modalBody}>
            <p className={styles.modalBodyHeader}>{t('otp.title')}</p>
            <div className={styles.modalBodyContent}>
              <p className={styles.modalBodyLine}>{t('otp.tips1')}</p>
              <p className={styles.modalBodyLine}>{t('otp.tips2')}</p>
              <p className={styles.modalBodyLine}>{t('otp.tips3')}</p>
              <div className={styles.input}>
                <Input
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
            </div>
          </div>
          <TicketSeparator />
          <div className={styles.modalFooter}>
            <Button onClick={registerOTP} loading={loadingRegisterOTP}>
              {t('common.submit')}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal visible={otp.verifyOTP}>
        <div className={styles.modal}>
          <div className={styles.modalBody}>
            <p className={styles.modalBodyHeader}>{t('otp.title')}</p>
            {initRequestOTP ? (
              <div className={styles.modalBodyContent}>
                <p className={styles.modalBodyLine}>{t('otp.tips1')}</p>
                <p className={styles.modalBodyLine}>{t('otp.tips2')}</p>
              </div>
            ) : (
              <div className={styles.modalBodyContent}>
                <p className={styles.modalBodyLine}>{t('otp.inputOTPCode')}</p>
                <div className={styles.input}>
                  <Input
                    value={code}
                    inputMode="numeric"
                    onChange={e => setCode(e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>
          <TicketSeparator />
          {initRequestOTP ? (
            <div className={styles.modalFooter}>
              <Button onClick={requestOTP} loading={loadingRequestOTP}>
                {t('otp.sendOTP')}
              </Button>
            </div>
          ) : (
            <div className={styles.modalFooter}>
              <Button
                color="standard"
                onClick={requestOTP}
                disabled={!validResend}
                loading={loadingRequestOTP}
              >
                {t('common.resend')}
                {!validResend &&
                  `(${t('ticket.second', {
                    count: Math.round(remainingSeconds / 1_000)
                  })})`}
              </Button>
              <Button onClick={verifyOTP} loading={loadingVerifyOTP}>
                {t('common.confirm')}
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default observer(OTP);
