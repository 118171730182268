import axios from 'axios';
import * as Sentry from '@sentry/react';
import {
  ClientError,
  ServerError,
  ConnectionError,
  RequestError,
  EticketError,
  EticketOTPError
} from './error';

const INTERNAL_SERVER_ERROR = 500;

const instance = axios.create();

instance.defaults.headers.post['Content-Type'] = 'application/json';

instance.interceptors.request.use(async config => {
  return config;
});

instance.interceptors.response.use(
  response => {
    return response.data;
  },
  async error => {
    let apiError;
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status < INTERNAL_SERVER_ERROR) {
        if (error.response.data) {
          if (error.response.data.id.startsWith('OTP')) {
            apiError = new EticketOTPError(
              error.response.data.metadata.REASON,
              error.response.data.id
            );
          } else {
            apiError = new EticketError(
              `${error.response.data.id} ${error.response.data.metadata.REASON}`
            );
          }
        } else {
          apiError = new ClientError();
        }
      } else {
        apiError = new ServerError();
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      apiError = new ConnectionError();
    } else {
      // Something happened in setting up the request that triggered an Error
      // message = 'An error occurred while setting up an API request.';
      Sentry.captureException(error);
      apiError = new RequestError();
    }
    apiError?.show();
    // return Promise.reject(error);
  }
);

export default instance;
