import { DEFAULT_LOCALE } from '@/constants/locale';
import { Locale } from '@/services/types';
import { Country, Language } from '@/services/enum';
import i18n from '@/i18n';

export function getValidLocale(lang: string) {
  if (lang.startsWith('ja')) {
    return 'ja';
  }
  if (lang.startsWith('ko')) {
    return 'ko';
  }
  if (lang.startsWith('en')) {
    return 'en';
  }
  if (lang.startsWith('zt')) {
    return 'zt';
  }
  if (lang.startsWith('zh')) {
    return 'zh';
  }

  return DEFAULT_LOCALE;
}

export function getCurrentLocale(currentLang?: string): Locale {
  const locale = currentLang || i18n.language;
  let lang = Language.JAPANESE;
  switch (locale) {
    case 'en': {
      lang = Language.ENGLISH;
      break;
    }
    case 'ja': {
      lang = Language.JAPANESE;
      break;
    }
    case 'ko': {
      lang = Language.KOREAN;
      break;
    }
    case 'zh': {
      lang = Language.CHINESE;
      break;
    }
    case 'zt': {
      lang = Language.CHINESE_T;
      break;
    }
  }
  return {
    language: lang,
    country: Country.UNIVERSAL
  };
}
