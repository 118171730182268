export const eventKey = {
  Escape: 'Escape',
  Enter: 'Enter',
  Space: '',
  ArrowLeft: 'ArrowLeft',
  ArrowUp: 'ArrowUp',
  ArrowRight: 'ArrowRight',
  ArrowDown: 'ArrowDown',
  Tab: 'Tab'
} as const;
